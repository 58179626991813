import $ from "jquery";

/*
 * 表示領域内に入ったら
 * -viewクラスを追加してelementを返却
 * repeatFlg: trueの場合は領域外に出たら-viewクラスを削除
 */
export default class InView {
  constructor(callback, element, offset, repeatFlg) {
    this.callback = callback;
    this.target = element;
    this.offset = offset;
    this.repeatFlg = repeatFlg || false;
    this.init();
  }

  init() {
    const self = this;
    if (typeof this.callback == "function") {
      const promise = this.inView();
      promise.done(function() {
        self.callback(self.target);
      });
      promise.fail(function() {
        self.callback();
      });
    }
  }

  inView() {
    const self = this;
    const d = $.Deferred();

    const offset = self.offset || "-20%";

    const options = {
      rootMargin: offset,
    }
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        if (!self.repeatFlg) {
          observer.disconnect();
        }
        self.target.classList.add("-view");
        d.resolve();
      } else {
        if (self.repeatFlg) {
          self.target.classList.remove("-view");
        }
      }
    }, options);
    observer.observe(self.target);

    return d.promise();
  }
}
