/*
 * ページ内リンク
 */
import _ from "underscore";
import $ from "jquery";

export default class Anchor {
  constructor() {
    this.trigger = document.getElementsByClassName("js-anchor");
    this.speed = 500;
    this.breakPoint = 768;
    this.posTopPc = 20; // スクロール先の位置をこの値の分だけ上に設定
    this.posTopSp = 15;
  }

  init() {
    this.anchor();
  }

  anchor() {

    // click event
    $(this.trigger).on("click.anchor", e => {
      e.preventDefault();
      e.stopPropagation();
      const href = e.currentTarget.attributes["href"].value;
      const target = $(href);
      if (target.length) {
        this.move(target, e.currentTarget);
      }
    });
  }

  move(target, elm) {
    const pos = this.getPos(target, elm);
    $('body,html').animate({ scrollTop: pos }, this.speed, 'swing');
  }

  // 移動先の位置を返却
  getPos(target, elm) {
    const offset = elm.getAttribute("data-offset");
    let pos;
    if (offset) {
      pos = target.offset().top + parseInt(offset);
    } else {
      pos = target.offset().top - this.posTopPc;
      if (window.innerWidth < this.breakPoint) {
        pos = target.offset().top - this.posTopSp;
      }
    }
    return pos;
  }

  leave() {
    $(this.trigger).off("click.anchor");
  }
}
