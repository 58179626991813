/*
 * QR ページjs
 */

import $ from "jquery";

export default class Qr {
  constructor() {
    this.lang = location.hash ? location.hash : '';
    this.container = document.getElementById("qrpage");
    this.init();
  }

  init() {
    this.linkToView();
    this.selectLang();
    this.link();
  }
  //リンクから
  linkToView() {
      if(this.lang) {
          const triggerWrap = this.container.getElementsByClassName("js-lang-btn-wrap");
          const targetWrap = this.container.getElementsByClassName("js-page-wrap");
          $(this.lang).show();
          $(this.lang).addClass('is-active');
          $(targetWrap).fadeIn();
          $("body, html").animate({ scrollTop: 0 }, 0);
          $(triggerWrap).fadeOut(1000);
      }
  }

  // 原語選択
  selectLang() {
    const trigger = this.container.getElementsByClassName("js-lang-btn");
    const triggerWrap = this.container.getElementsByClassName("js-lang-btn-wrap");
    const targetWrap = this.container.getElementsByClassName("js-page-wrap");

    $(trigger).on('click', function(e){
        e.preventDefault();
        $($(this).attr('href')).show();
        $($(this).attr('href')).addClass('is-active');
        $(targetWrap).fadeIn();
        $(triggerWrap).fadeOut(1000);
    });
  }
  //next back link
  link() {
      const trigger = this.container.getElementsByClassName("js-link-btn");
      const lang = this.lang;
      $(trigger).on('click', function(e){
          e.preventDefault();
          let url = $(this).attr('href');
          let lang = $('.js-contents.is-active').attr('id');
          location.href = url + '#' + lang;
      });
  }

}
