/*
 * 動画再生モーダル
 */

import $ from "jquery";
import _ from "underscore";
import ModalLib from "../lib/ModalLib";

export default class MovielLib {
  constructor() {
    this.modal = document.getElementById("modal");
    this.target = document.getElementsByClassName("js-movie");

    // lib
    this.modalLib = new ModalLib();
  }

  // モーダルを開いて動画を再生
  openMovieModal(elm) {
    this.modalLib.openModal(elm).then(() => {
      const id = elm.dataset.modal;
      const modal = document.getElementById(id);
      if (modal) {
        self.movie = modal.getElementsByTagName("video")[0];
        if (self.movie) {
          self.movie.play();
        }
      }
    });
  }

  // モーダル内の全ての動画を停止
  pauseAllMovie() {
    const movieElm = document.getElementById("modal")
                             .getElementsByTagName("video");
    Array.prototype.forEach.call(movieElm, elm => {
      elm.pause();
    });
  }

  // -hoverクラス削除
  removeHoverClass() {
    Array.prototype.forEach.call(this.target, elm => {
      elm.classList.remove("-hover");
    });

    // topListの-hoverクラス削除
    const listElm = document.getElementsByClassName("topList");
    Array.prototype.forEach.call(listElm, elm => {
      elm.classList.remove("-hover");
    });
  }

}
