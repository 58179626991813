/*
 * キャンプ ページjs
 */

import $ from "jquery";
import _ from "underscore";
import IsPassiveSupport from "../lib/IsPassiveSupport";
import InView from "../lib/InView";
import UA from "../lib/UA";
import PerfectScrollbar from "perfect-scrollbar";

export default class Camp {
  constructor() {
    this.container = document.getElementById("camp");
    this.video = document.getElementById("bgVideo");
    this.historyScrollElm = document.getElementsByClassName("js-campDaysScroll")[0];
    // lib
    this.ua = new UA();

    this.breakPoint = 769;
    // history auto scroll
    this.scrollX = 0;
    this.scrollId;
    this.stopFlg = true;
    this.moveStartFlg = false;

    this.init();
  }

  init() {
    this.inView();
    this.bgVideo();
    this.history();
  }

  // inView event
  inView() {
    const target = this.container.getElementsByClassName("js-inView");
    const callback = elm => {
      if (elm.classList.contains("campDays__content")) {
        // 自動スクロール開始
        _.delay(() => {
          this.stopFlg = false;
        }, 1200);
      }
    };
    Array.prototype.forEach.call(target, elm => {
      const offset = elm.getAttribute("data-offset") || "";
      new InView(callback, elm, offset);
    });
  }

  // 背景動画再生
  bgVideo() {
    $(this.video).on("canplaythrough", e => {
      $(this.video).off("canplaythrough");
      this.video.currentTime = 0;
      this.video.play();
      _.delay(() => {
        this.video.classList.add("-show");
      }, 1000);
    });
  }

  history() {
    const scrollElm = document.getElementsByClassName("js-campDaysScroll")[0];

    // スクロールバーカスタマイズ
    const ps = new PerfectScrollbar(scrollElm, {
      wheelSpeed: 2,
      wheelPropagation: true,
      minScrollbarLength: 20
    });

    // 自動スクロール
    this.scrollId = setInterval(
      (function(self) {
        return function() {
          self.autoScroll();
        }
      })(this),
      20
    );

    // 操作時に自動スクロール停止
    if (this.ua.device() == "other") {
      this.historyScrollElm.addEventListener(
        "mouseenter",
        e => {
          this.stopFlg = true;
        },
        false
      );
      this.historyScrollElm.addEventListener(
        "mouseleave",
        e => {
          this.scrollX = this.historyScrollElm.scrollLeft;
          this.stopFlg = false;
        },
        false
      );
    } else {
      this.historyScrollElm.addEventListener(
        "touchstart",
        e => {
          this.stopFlg = true;
        },
        false
      );
      this.historyScrollElm.addEventListener(
        "touchend",
        e => {
          this.scrollX = this.historyScrollElm.scrollLeft;
          this.stopFlg = false;
        },
        false
      );
    }

    this.historyScrollElm.addEventListener(
      "scroll",
      e => {
        if (this.stopFlg && !this.moveStartFlg) {
          // 手動で横スクロールした場合、自動スクロール停止
          if (Math.abs(this.historyScrollElm.scrollLeft - this.scrollX) > 10) {
            clearInterval(this.scrollId);
          }
        }
      },
      false
    );
  }

  autoScroll() {
    if (this.stopFlg) return false;
    this.historyScrollElm.scrollLeft = ++this.scrollX;
    if (this.scrollX < this.historyScrollElm.scrollWidth - this.historyScrollElm.clientWidth) {
    } else {
      this.stopFlg = true;
      this.scrollX = 0;
      this.moveStartFlg = true;
      _.delay(() => {
        $(this.historyScrollElm).animate({scrollLeft: 0}, 800);
        _.delay(() => {
          this.stopFlg = false;
          this.moveStartFlg = false;
        }, 1000);
      }, 1000);
    }
  }

}
