import $ from "jquery";
import _ from "underscore";

export default class Contact {
  constructor() {
    this.form = document.getElementsByClassName("js-contactForm")[0];
    this.init();
  }

  init() {
    if (!this.form) return false;

    this.bindEvents();

    const formFileElms = this.form.getElementsByClassName('js-form-file');
    if (formFileElms.length) {
      Array.prototype.forEach.call(formFileElms, elm => {
        this.fileEvents(elm);
      });
    }
  }

  bindEvents() {
    const inputElms = this.form.getElementsByClassName("textBox");
    const textareaElms = this.form.getElementsByClassName("textArea");

    // エラー表示時に入力したらエラー解除
    if (inputElms.length) {
      Array.prototype.forEach.call(inputElms, elm => {
        elm.addEventListener("keyup", e => {
          if (e.target.classList.contains("-error")) {
            e.target.classList.remove("-error");
          }
        });
      });
    }

    if (textareaElms.length) {
      Array.prototype.forEach.call(textareaElms, elm => {
        elm.addEventListener("keyup", e => {
          if (e.target.classList.contains("-error")) {
            e.target.classList.remove("-error");
          }
        });
      });
    }
  }

  // 添付ファイル操作関連イベント
  fileEvents(container) {
    const addBtnElm = container.getElementsByClassName("js-file-add-btn")[0];
    const fileElms = container.getElementsByClassName("js-file");

    // 追加ボタン
    if (addBtnElm) {
      addBtnElm.addEventListener("click", e => {
        e.preventDefault();
        const targetInputElm = this.getTargetFileInput(container);
        if (targetInputElm) {
          targetInputElm.click();
        }
      });
    }

    if (fileElms.length) {
      Array.prototype.forEach.call(fileElms, elm => {
        const inputElm = elm.getElementsByClassName("js-file-input")[0];
        const fileNameElm = elm.getElementsByClassName("js-file-name")[0];
        const removeBtn = elm.getElementsByClassName("js-file-remove-btn")[0];
        const fileTmpNameHiddenElm = elm.getElementsByClassName("js-file-tmp-name-hidden")[0];
        const fileNameHiddenElm = elm.getElementsByClassName("js-file-name-hidden")[0];

        // ファイル名 初期表示設定
        if (fileNameHiddenElm) {
          elm.classList.remove("is-hide");
          if (fileNameElm) {
            fileNameElm.innerText = fileNameHiddenElm.value;
          }
        } else {
          elm.classList.add("is-hide");
        }

        // ファイル選択後イベント
        if (inputElm) {
          inputElm.addEventListener("change", () => {
            if (inputElm.files[0].name && fileNameElm) {
              fileNameElm.innerText = inputElm.files[0].name;
            }
            elm.classList.remove("is-hide");
            this.changeAddFileBtnState(container);
          });
        }

        // ファイル削除イベント
        if (removeBtn) {
          removeBtn.addEventListener("click", () => {
            const fileElm = $(removeBtn).closest(".js-file")[0];
            if (inputElm) {
              inputElm.value = "";
            }
            if (fileNameElm) {
              fileNameElm.innerText = "";
            }
            if (fileElm) {
              const fileTmpNameHiddenElm = fileElm.getElementsByClassName("js-file-tmp-name-hidden")[0];
              const fileNameHiddenElm = fileElm.getElementsByClassName("js-file-name-hidden")[0];
              if (fileTmpNameHiddenElm) {
                fileTmpNameHiddenElm.parentNode.removeChild(fileTmpNameHiddenElm);
              }
              if (fileNameHiddenElm) {
                fileNameHiddenElm.parentNode.removeChild(fileNameHiddenElm);
              }
            }
            elm.classList.add("is-hide");
            this.changeAddFileBtnState(container);
          });
        }
      });
    }

    // ファイル選択ボタンの初期設定
    this.changeAddFileBtnState(container);
  }

  // ファイル選択ボタンの状態設定
  changeAddFileBtnState(container) {
    const addBtnElm = document.getElementsByClassName("js-file-add-btn")[0];
    if (!addBtnElm) return false;

    if (!this.getTargetFileInput(container)) {
      addBtnElm.classList.add("is-hide");
    } else {
      addBtnElm.classList.remove("is-hide");
    }
  }

  // 追加対象のinput type=file要素を返却
  getTargetFileInput(container) {
    const elm = (() => {
      let targetElm = "";
      const fileElms = container.getElementsByClassName("js-file");

      if (fileElms.length) {
        Array.prototype.forEach.call(fileElms, elm => {
          const inputElm = elm.getElementsByClassName("js-file-input")[0];
          const fileNameElm = elm.getElementsByClassName("js-file-name")[0];
          if (inputElm && fileNameElm) {
            const name = fileNameElm.innerText;
            if (targetElm == "" && name == "") targetElm = inputElm;
          }
        });
      }

      return targetElm;
    })();

    return elm;
  }
}
