
export default class Topics {
  constructor() {
  }

  init() {
  }

  // leave event
  leave() {
  }
}
