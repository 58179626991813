/*
 * むかわ竜 ページjs
 */

import _ from "underscore";
import $ from "jquery";
import IsPassiveSupport from "../lib/IsPassiveSupport";
import InView from "../lib/InView";
import UA from "../lib/UA";
import PerfectScrollbar from "perfect-scrollbar";

export default class Mukawaryu {
  constructor() {
    this.container = document.getElementById("mukawaryu");
    this.historyScrollElm = document.getElementsByClassName("js-historyScroll")[0];

    // lib
    this.ua = new UA();

    this.breakPoint = 769;

    // history auto scroll
    this.scrollX = 0;
    this.scrollId;
    this.stopFlg = true;
    this.moveStartFlg = false;

    this.init();
  }

  init() {
    this.unlockKv();
    this.scrollEvent();
    this.inView();
    this.history();
  }

  // KV固定解除処理
  unlockKv() {
    const self = this;
    if (this.ua.device() == "other") {
      // PC
      const mousewheelevent = 'onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll';
      $(document).on(mousewheelevent,function(e){
        const delta = e.originalEvent.deltaY ? -(e.originalEvent.deltaY) : e.originalEvent.wheelDelta ? e.originalEvent.wheelDelta : -(e.originalEvent.detail);

        if (delta < 0){
          // マウスホイールを下にスクロールしたら固定解除
          self.container.classList.remove("-noscroll");
          $(document).off(mousewheelevent);
        }
      });
    } else {
      // PC以外
      let startX, moveX;
      $(document).on("touchstart", () => {
        startX = event.touches[0].pageY;
      });

      $(document).on("touchmove", () => {
        moveX = event.changedTouches[0].pageY;
        if (moveX < startX - 30) {
          // 上にタッチムーブしたら固定解除
          self.container.classList.remove("-noscroll");
          $(document).off("touchstart");
          $(document).off("touchmove");
        }
      });
    }
  }

  // pc幅 key visual image
  // スクロールでfixed解除
  // 特定の位置まできたらテキストにかから内容に右に移動
  scrollEvent() {
    const self = this;
    const isPassive = new IsPassiveSupport();

    const containerElm = document.getElementById("mukawaryu");
    const headerElm = document.getElementsByClassName("header")[0];

    const titleElm = document.getElementsByClassName("mukawaryuKv__title")[0];
    const fixedImg = document.getElementsByClassName("js-fixed-img")[0];
    const txtElm = document.getElementsByClassName("mukawaryuSection__content")[0]
                           .getElementsByClassName("txt")[0]
                           .getElementsByTagName("img")[0];
    const scrollElm = document.getElementsByClassName("js-scrollArea")[0];
    const endElm = document.getElementsByClassName("mukawaryuEndSection")[0];
    const menuElm = document.getElementsByClassName("menuBottom")[0];

    const singlePageElm = document.getElementsByClassName("singlePage")[0];

    let isSp = (window.innerWidth < this.breakPoint) ? true : false;
    let titleLeftPos = 0; // タイトル+画像が中央にくるようなタイトルの左位置設定
    let leftPosKv = 0; // kvにかからない left position
    let leftPos = 0;   // textにかからない left position
    let startPos = 0;  // 最初のtxtの位置
    let endPos = 0;    // 画像停止位置
    let baseTop = 25;   // 基準にするtop位置

    let headerHidePos = 0; // ヘッダー非表示位置
    let pageEndPos = 0;

    let timeout;
    let menuFlg = false; // メニュー表示フラグ
    let lockFlg = false;

    // 位置を更新
    const calcPos = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      // kv image 位置設定
      const scrollRect = scrollElm.getBoundingClientRect();
      const titleRect = titleElm.getElementsByTagName("img")[0].getBoundingClientRect();
      const imageRect = fixedImg.getElementsByTagName("img")[0].getBoundingClientRect();
      const txtRect = txtElm.getBoundingClientRect();
      const endElmRect = endElm.getBoundingClientRect();
      leftPosKv = titleRect.left + titleRect.width + 20;
      leftPos = txtRect.left + txtRect.width + 20;
      startPos = txtRect.top + scrollTop - titleRect.height - 80;
      endPos = scrollRect.top + scrollTop + scrollRect.height - window.innerHeight + (window.innerHeight - imageRect.height);

      if (imageRect.height >= 900) {
        baseTop = (window.innerHeight - imageRect.height) / 2 - 25;
      } else {
        baseTop = (window.innerHeight - imageRect.height) / 2;
      }

      // ヘッダー非表示位置
      headerHidePos = window.innerHeight / 3 * 2;

      // scroll image
      pageEndPos = containerElm.clientHeight - window.innerHeight / 2 * 3;
    }

    // タイトル左位置更新
    const calcTitleLeftPos = () => {
      const titleRect = titleElm.getElementsByTagName("img")[0].getBoundingClientRect();
      if (titleRect.width + (titleRect.width * 9 / 4) < titleElm.clientWidth) {
        titleLeftPos = (titleElm.clientWidth - (titleRect.width + (titleRect.width * 9 / 4))) / 2;
      } else {
        titleLeftPos = 0;
      }
    }

    // タイトル左位置設定
    const setTitleLeftPos = () => {
      titleElm.getElementsByTagName("img")[0].style.left = titleLeftPos + "px";
    }

    // PC位置を設定
    const setPos = () => {
      if (!isSp) {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop >= startPos) {
          fixedImg.style.left = leftPos + "px";

          // 下固定判定
          if (scrollTop >= endPos) {
            fixedImg.style.top = endPos - scrollTop + baseTop + "px";
          } else {
            fixedImg.style.top = baseTop + "px";
          }
        } else {
          fixedImg.style.left = leftPosKv + "px";
          fixedImg.style.top = baseTop + "px";
        }
      }
    }

    // SP位置を設定
    const setPosSp = () => {
      const titleRect = titleElm.getBoundingClientRect();
      const height = window.innerHeight - titleRect.top - titleRect.height - 20;
      fixedImg.style.height = height + "px";
    }

    const resetPos = () => {
      fixedImg.removeAttribute("style");
      titleElm.getElementsByTagName("img")[0].removeAttribute("style");
    }

    // メニュー表示
    const showMenu = () => {
      if (menuFlg) return;
      if (lockFlg) return;
      lockFlg = true;
      menuFlg = true;
      menuElm.classList.remove("-none");
      _.delay(() => {
        menuElm.classList.remove("-hide");
        lockFlg = false;
      }, 500);
    }

    // メニュー非表示
    const hideMenu = () => {
      if (!menuFlg) return;
      if (lockFlg) return;
      lockFlg = true;
      menuFlg = false;
      menuElm.classList.add("-hide");
      _.delay(() => {
        menuElm.classList.add("-none");
        lockFlg = false;
      }, 500);
    }

    // kv画像読み込み後にページを表示
    const firstLoadImage = document.getElementsByClassName("mukawaryuKv__img")[0].getElementsByTagName("img")[0];
    $(firstLoadImage).imageready(function($notLoadedImages) {
      if (isSp) {
        setPosSp();
      } else {
        calcTitleLeftPos();
        setTitleLeftPos();
        calcPos();
        setPos();
      }
      _.delay(() => {
        document.getElementsByTagName("body")[0].classList.remove("-hide");
        document.getElementsByClassName("mukawaryuKv__img")[0].classList.add("-show");
      }, 200);
    }, {
      allowTimeout: true,
      timeoutDuration: 2000
    });

    // スクロールイベント
    const onScroll = () => {
      if (timeout) {
        cancelAnimationFrame(timeout);
      }
      timeout = requestAnimationFrame(() => {
        calcPos();
        setPos();

        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        // ヘッダー表示非表示
        if (scrollTop > headerHidePos) {
          if (!headerElm.classList.contains("-hide")) {
            headerElm.classList.add("-hide");
          }
        } else {
          if (headerElm.classList.contains("-hide")) {
            headerElm.classList.remove("-hide");
          }
        }

        // メニュー表示非表示
        if (scrollTop >= window.innerHeight) {
          showMenu();
        } else {
          hideMenu();
        }

        // SCROLL Image 表示非表示
        if (scrollTop > window.innerHeight && scrollTop >= pageEndPos) {
          singlePageElm.classList.add("-hideScroll");
        } else {
          singlePageElm.classList.remove("-hideScroll");
        }
      });
    }
    document.addEventListener(
      "scroll",
      () => {
        onScroll();
      },
      isPassive.check() ? { passive: true } : false
    );

    // リサイズイベント
    const debouncedOnResize = _.debounce(() => {
      const prev_isSp = isSp;
      isSp = (window.innerWidth < this.breakPoint) ? true : false;
      if (isSp) {
        if (prev_isSp != isSp) {
          resetPos();
          setPosSp();
        }
      } else {
        resetPos();
        calcTitleLeftPos();
        setTitleLeftPos();
        calcPos();
        setPos();
      }
    }, 600);
    window.addEventListener(
      "resize",
      () => {
      window.requestAnimationFrame(debouncedOnResize);
    }, false);
  }

  // inView event
  inView() {
    const scrollElm = document.getElementsByClassName("js-historyScroll")[0];

    const target = this.container.getElementsByClassName("js-inView");
    const callback = elm => {
      if (elm.classList.contains("history__content")) {
        // 自動スクロール開始
        _.delay(() => {
          this.stopFlg = false;
        }, 1200);
      }
    };
    Array.prototype.forEach.call(target, elm => {
      const offset = elm.getAttribute("data-offset") || "";
      new InView(callback, elm, offset);
    });
  }

  history() {
    const scrollElm = document.getElementsByClassName("js-historyScroll")[0];

    // スクロールバーカスタマイズ
    const ps = new PerfectScrollbar(scrollElm, {
      wheelSpeed: 2,
      wheelPropagation: true,
      minScrollbarLength: 20
    });

    // 自動スクロール
    this.scrollId = setInterval(
      (function(self) {
        return function() {
          self.autoScroll();
        }
      })(this),
      20
    );

    // 操作時に自動スクロール停止
    if (this.ua.device() == "other") {
      this.historyScrollElm.addEventListener(
        "mouseenter",
        e => {
          this.stopFlg = true;
        },
        false
      );
      this.historyScrollElm.addEventListener(
        "mouseleave",
        e => {
          this.scrollX = this.historyScrollElm.scrollLeft;
          this.stopFlg = false;
        },
        false
      );
    } else {
      this.historyScrollElm.addEventListener(
        "touchstart",
        e => {
          this.stopFlg = true;
        },
        false
      );
      this.historyScrollElm.addEventListener(
        "touchend",
        e => {
          this.scrollX = this.historyScrollElm.scrollLeft;
          this.stopFlg = false;
        },
        false
      );
    }

    this.historyScrollElm.addEventListener(
      "scroll",
      e => {
        if (this.stopFlg && !this.moveStartFlg) {
          // 手動で横スクロールした場合、自動スクロール停止
          if (Math.abs(this.historyScrollElm.scrollLeft - this.scrollX) > 10) {
            clearInterval(this.scrollId);
          }
        }
      },
      false
    );
  }

  autoScroll() {
    if (this.stopFlg) return false;
    this.historyScrollElm.scrollLeft = ++this.scrollX;
    if (this.scrollX < this.historyScrollElm.scrollWidth - this.historyScrollElm.clientWidth) {
    } else {
      this.stopFlg = true;
      this.scrollX = 0;
      this.moveStartFlg = true;
      _.delay(() => {
        $(this.historyScrollElm).animate({scrollLeft: 0}, 800);
        _.delay(() => {
          this.stopFlg = false;
          this.moveStartFlg = false;
        }, 1000);
      }, 1000);
    }
  }
}
