import $ from "jquery";
import _ from "underscore";
import MovieLib from "../lib/MovieLib";
import UA from "../lib/UA";

export default class Movie {
  constructor() {
    this.modal = document.getElementById("modal");
    this.target = document.getElementsByClassName("js-movie");
    this.overlay = document.getElementsByClassName("modal__overlay")[0];

    // lib
    this.movieLib = new MovieLib();
    this.ua = new UA();
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    // モーダルを開いて動画を再生
    this.debouncedOnOpen = _.debounce(elm => {
      this.movieLib.openMovieModal(elm);
    }, 0);
    Array.prototype.forEach.call(this.target, elm => {
      const type = elm.getAttribute("data-movie-type");
      $(elm).on("click.movie", e => {
        e.preventDefault();
        e.stopPropagation();
        let movieOpenFlg = true;
        if (type == "hover") {
          if (elm.classList.contains("-hover")) {
            movieOpenFlg = true;
          } else {
            this.movieLib.removeHoverClass();
            elm.classList.add("-hover");
            movieOpenFlg = false;
          }
        }
        if (movieOpenFlg == true) {
          this.debouncedOnOpen(elm);
        }
      });

      // ホバーイベント
      if (this.ua.device() == "other") {
        if (type == "hover") {
          $(elm).on("mouseenter.movie", () => {
            elm.classList.add("-hover");
          });
          $(elm).on("mouseleave.movie", () => {
            elm.classList.remove("-hover");
          });
        }
      }
    });

    // modal overlay click
    // pause movie
    // 動画を停止してモーダルを閉じる
    this.debouncedOnClose = _.debounce(() => {
      this.movieLib.pauseAllMovie();
      this.movieLib.removeHoverClass();
    }, 0);
    $(this.overlay).on("click.movieOverlay", e => {
      e.preventDefault();
      e.stopPropagation();
      this.debouncedOnClose();
    });
  }

  leave() {
    this.movieLib.pauseAllMovie();
    this.movieLib.removeHoverClass();

    // 登録イベント削除
    $(this.overlay).off("click.movieOverlay");
    Array.prototype.forEach.call(this.target, elm => {
      $(elm).off("click.movie");
      $(elm).off("mouseenter.movie");
      $(elm).off("mouseleave.movie");
    });
  }
}
