import $ from "jquery";
import _ from "underscore";

export default class MenulLib {
  constructor() {
    this.body = document.getElementsByTagName("body")[0];
    this.menuBtn = document.getElementsByClassName("js-menuBtn")[0];
    this.menuModal = document.getElementsByClassName("js-menuModal")[0];
  }

  menuOpen() {
    $(this.menuModal).off("webkitTransitionEnd mozTransitionEnd oTransitionEnd transitionend");
    this.menuBtn.classList.add("-open");
    this.body.classList.add("-menuOpen");
    this.menuModal.classList.add("-visible");
    _.delay(() => {
      this.menuModal.classList.add("-show");
    }, 100);
  }
  menuClose() {
    const d = $.Deferred();

    this.menuBtn.classList.remove("-open");
    this.body.classList.remove("-menuOpen");
    this.menuModal.classList.remove("-show");
    $(this.menuModal).on("webkitTransitionEnd mozTransitionEnd oTransitionEnd transitionend", () => {
      this.menuModal.classList.remove("-visible");
      $(this.menuModal).off("webkitTransitionEnd mozTransitionEnd oTransitionEnd transitionend");
      d.resolve();
    });

    return d.promise();
  }
}
