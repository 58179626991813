
/*
 * 静的ページjs
 *
 */

// 共通js
import Menu from "../pages/Menu";
import Anchor from "../pages/Anchor";
import Modal from "../pages/Modal";
import Movie from "../pages/Movie";

// ページjs
import Contact from "./Contact";   // contact
import Food from "./Food";         // food
import Sight from "./Sight";       // sight
import Tour from "./Tour";         // tour
import Mukawaryu from "./Mukawaryu";  // mukawaryu
import Camp from "./Camp";  // camp
import Qr from "./Qr";  // qr
import Seasonal from "./Seasonal";   // Seasonal


export default class Static {
  constructor() {}
  init() {
    // 共通js
    this.menu = new Menu();
    this.menu.init();

    this.anchor = new Anchor();
    this.anchor.init();

    this.modal = new Modal();
    this.modal.init();

    this.movie = new Movie();
    this.movie.init();

    // ページjs
    if (document.getElementById("contact")) { new Contact(); }
    if (document.getElementById("food")) { new Food(); }
    if (document.getElementById("sight"))   { new Sight(); }
    if (document.getElementById("tour"))    { new Tour(); }
    if (document.getElementById("mukawaryu")) { new Mukawaryu(); }
    if (document.getElementById("camp")) { new Camp(); }
    if (document.getElementById("qrpage")) { new Qr(); }
    if (document.getElementById("seasonal")) { new Seasonal(); }
  }
}
