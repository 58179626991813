/*
 * 探訪 ページjs
 */

import $ from "jquery";
import _ from "underscore";
import InView from "../lib/InView";

export default class Sight {
  constructor() {
    this.container = document.getElementById("sight");
    this.moreSlider;
    this.init();
  }

  init() {
    this.inView();
    this.inViewBand();
    this.moreSlider();
  }

  // moreslider
  moreSlider() {
    const target = this.container.getElementsByClassName("js-moreSlide");
    const slideOpt = {
      slidesToShow: 4,
      slidesToScroll: 4,
      autoplay: true,
      autoplaySpeed: 5000,
      cssEase: "linear",
      pauseOnHover: false,
      dots: true,
      arrows: true,
      draggable: false,
      responsive: [
        {
          breakpoint: 768, //767px以下のサイズに適用
          settings: {
            slidesToScroll: 2,
            slidesToShow: 2,
            initialSlide: 2,
            centerPadding: "20%",
          }
        }
      ]
    };

    this.kvSlider = $(target).slick(slideOpt);
  }

  // inView event
  inView() {
    const target = this.container.getElementsByClassName("js-inView");
    const callback = elm => {};
    Array.prototype.forEach.call(target, elm => {
      const offset = elm.getAttribute("data-offset") || "";
      new InView(callback, elm, offset);
    });
  }

  // inView txt event
  inViewBand() {
    const target = this.container.getElementsByClassName("js-inViewBand");
    const callback = elm => {};
    Array.prototype.forEach.call(target, elm => {
      const offset = elm.getAttribute("data-offset") || "";
      new InView(callback, elm, offset);
    });
  }
}
