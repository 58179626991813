import _ from "underscore";
import $ from "jquery";
import Barba from "barba.js";
import Static from "./static";

$(() => {
  // 同一ページのリンクを無効
  const links = document.querySelectorAll("a[href]");
  const linkNode = Array.prototype.slice.call(links, 0);
  let defaultHref;
  linkNode.forEach(elm => {
    elm.addEventListener("click", e => {
      if (e.currentTarget.href === window.location.href) {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  });

  // 新しいページが準備できたときの処理
  Barba.Dispatcher.on(
    "newPageReady",
    (currentStatus, oldStatus, container, newPageRawHTML) => {
      // 進む、戻るでスクロール位置を復元しない
      history.scrollRestoration = "manual";
      if (Barba.HistoryManager.history.length === 1) {
        defaultHref = window.location.href;
        return;
      }

      // metaタグ更新
      const head = document.head;
      const newPageRawHead = newPageRawHTML.match(
        /<head[^>]*>([\s\S.]*)<\/head>/i
      )[0];
      const newPageHead = document.createElement("head");
      newPageHead.innerHTML = newPageRawHead;

      // 更新するタグ
      const removeHeadTags = [
        "meta[name='keywords']",
        "meta[name='description']",
        "meta[property^='og']"
      ].join(",");
      const headTags = head.querySelectorAll(removeHeadTags);
      for (let i = 0, len = headTags.length; i < len; i++) {
        head.removeChild(headTags[i]);
      }
      const newHeadTags = newPageHead.querySelectorAll(removeHeadTags);

      for (let i = 0, len = newHeadTags.length; i < len; i++) {
        head.appendChild(newHeadTags[i]);
      }
    }
  );

  // ページ遷移トランジション
  var FadeTransition = Barba.BaseTransition.extend({
    start: function() {
      Promise
        .all([this.newContainerLoading, this.fadeOut()])
        .then(this.fadeIn.bind(this));
    },

    fadeOut: function() {
      this.oldContainer.classList.add("fade-out");
      return new Promise(function(resolve, reject) {
        _.delay(() => {
          resolve();
        }, 700);
      });
    },

    fadeIn: function() {
      $(document).scrollTop(0);
      this.newContainer.classList.add("fade-in");
      this.done();
    }
  });

  // Barbaに作成した遷移処理を指定
  Barba.Pjax.getTransition = function() {
    // let transitionObj = FadeTransition;
    // let namespace = Barba.HistoryManager.prevStatus().namespace;
    return FadeTransition;
  };

  Barba.Pjax.Dom.wrapperId = "pjax";
  Barba.Pjax.Dom.containerClass = "pjaxContainer";

  if (document.getElementById("pjax")) {
    // Pjaxページ
    Barba.Prefetch.init();
    Barba.Pjax.start();
    Barba.Pjax.cacheEnabled = false;
  } else {
    // 通常ページ
    if (document.getElementsByTagName("main")[0].getAttribute("id") == "mukawaryu") {
    } else {
      document.getElementsByTagName("body")[0].classList.remove("-hide");
    }
    const staticPage = new Static();
    staticPage.init();
  }
});
