/*
 * メニューモーダル内処理
 */

import _ from "underscore";
import MenuLib from "../lib/MenuLib";

export default class Menu {
  constructor() {
    this.menuModal = document.getElementsByClassName("js-menuModal")[0];
    this.linkList = this.menuModal.getElementsByTagName("a");

    // lib
    this.menuLib = new MenuLib();
  }

  init() {
    this.linkClick();
  }

  // リンククリック時の処理
  // 表示中のページの場合にメニューを閉じる
  linkClick() {
    this.debouncedOnLinkClick = _.debounce(elm => {
      const link = elm.getAttribute("href");
      if (location.pathname == link) {
        this.menuLib.menuClose();
      }
    }, 0);
    Array.prototype.forEach.call(this.linkList, elm => {
      elm.addEventListener(
        "click",
        e => {
          this.debouncedOnLinkClick(elm);
        },
        false
      );
    });
  }
}
