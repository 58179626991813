/*
 * pageTop処理
 */
import _ from "underscore";
import $ from "jquery";
import IsPassiveSupport from "../lib/IsPassiveSupport";

export default class PageTop {
  constructor() {
    this.trigger = document.getElementsByClassName("js-gotoTop")[0];
    this.timeout;
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    const self = this;
    const isPassive = new IsPassiveSupport();

    // click event
    this.debouncedOnClick = _.debounce(elm => {
      $("body, html").animate({ scrollTop: 0 }, 500);
    }, 0);
    this.trigger.addEventListener(
      "click",
      e => {
        e.preventDefault();
        this.debouncedOnClick();
      },
      false
    );

    // scroll event
    this.onScroll = () => {
      if (this.timeout) {
        cancelAnimationFrame(this.timeout);
      }
      this.timeout = requestAnimationFrame(() => {
        self.scroll();
      });
    }
    document.addEventListener(
      "scroll",
      () => {
        this.onScroll();
      },
      isPassive.check() ? { passive: true } : false
    );
  }

  scroll() {
    if (window.scrollY > window.innerHeight / 2) {
      this.trigger.classList.add("-show");
    } else {
      this.trigger.classList.remove("-show");
    }
  }
}
