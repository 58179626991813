/*
 * ACCESS ページjs
 */

import _ from "underscore";

export default class Museum {
  constructor() {}

  init() {
    // tab切り替え
    this.tab();
  }

  // tab切り替え
  tab() {
    const container = document.getElementsByClassName("js-accessTab")[0];
    const menu = container.getElementsByClassName("js-accessTabMenu")[0]
                          .getElementsByTagName("li");
    const content = container.getElementsByClassName("js-accessTabContent")[0]
                             .getElementsByClassName("accessTab__contentInner");

    const debouncedOnTabClick = _.debounce((elm, index) => {
      if (elm.classList.contains("-active")) return false;

      Array.prototype.forEach.call(menu, (elm, c_index) => {
        if (c_index == index) {
          elm.classList.add("-active");
        } else {
          elm.classList.remove("-active");
        }
      });

      Array.prototype.forEach.call(content, (elm, c_index) => {
        if (c_index == index) {
          elm.classList.add("-show");
        } else {
          elm.classList.remove("-show");
        }
      });
    }, 0);

    Array.prototype.forEach.call(menu, (elm, index) => {
      elm.addEventListener(
        "click",
        e => {
          e.preventDefault();
          e.stopPropagation();
          debouncedOnTabClick(elm, index);
        },
        false
      );
    });
  }

  // leave event
  leave() {
  }
}
