/*
 *  ページjs
 */

import $ from "jquery";
import _ from "underscore";
import AOS from "aos";
import Rellax from "rellax";

export default class Seasonal {
  constructor() {
    this.container = document.getElementById("seasonal");
    this.kvBgItems = [];
    this.mql = window.matchMedia('only screen and (max-width: 768px)');
    this.rellax;
    this.rellax2;
    this.init();
  }

  init() {
    if (!this.container) return false;

    this.kvInit();
    this.fadeInImages();
    this.lazyLoadImages();
    this.inView();
    this.setRellax();
    this.loopItem();
    this.bindEvents();
  }

  bindEvents() {
    // 画像読み込み時の処理
    const debouncedOnImageLoad = _.debounce(() => {
      AOS.refresh()
      if (this.rellax && this.rellax.refresh) {
        this.rellax.refresh();
      }
      if (this.rellax2 && this.rellax2.refresh) {
        this.rellax2.refresh();
      }
    }, 100);
    const img = document.getElementsByTagName("img");
    if (img.length) {
      Array.prototype.forEach.call(img, elm => {
        elm.addEventListener("load", () => {
          debouncedOnImageLoad();
        });
      });
    }

    // コンテンツサイズ変更時の処理
    const debouncedOnContentResize = _.debounce(() => {
      AOS.refresh()
      if (this.rellax && this.rellax.refresh) {
        this.rellax.refresh();
      }
      if (this.rellax2 && this.rellax2.refresh) {
        this.rellax2.refresh();
      }
    }, 400);
    const resizeObserver = new ResizeObserver(entries => {
      debouncedOnContentResize();
    });
    resizeObserver.observe(document.body)

    // 画像遅延読み込み対応 アンカーリンク
    const anchorLinkElms = this.container.getElementsByClassName('js-custom-anchor');
    if (anchorLinkElms.length) {
      Array.prototype.forEach.call(anchorLinkElms, elm => {
        elm.addEventListener("click", e => {
          e.preventDefault();
          const hash = elm.hash;
          const target = $(hash);
          if (target.length) {
            this.moveAnchorLink(target);
          }
        });
      });
    }

    // PCSP切り替わり時の処理
    const changeBreakPoint = (mq) => {
      this.setRellax();
    };
    this.mql.addListener(changeBreakPoint);
  }

  kvInit() {
    const kvElm = this.container.getElementsByClassName('js-seasonal-kv')[0];
    if (!kvElm) return false;

    const itemElms = this.container.getElementsByClassName('js-seasonal-kv-bg');
    if (itemElms.length) {
      this.kvBgItems = itemElms;
    }

    _.delay(() => {
      kvElm.classList.add('is-show');
      this.kvShow(0);

      _.delay(() => {
        this.kv();
      }, 1000);
    }, 1000);
  }

  kv() {
    const self = this;

    const bar = this.container.getElementsByClassName('js-seasonal-kv-bar')[0];
    if (!bar) return false;

    const kvDuration = 5000;
    let kvInterval;
    let kvCurrentNum = 0;
    let width = 0;

    const loop = () => {
      if (width >= 100) {
        kvCurrentNum++;
        if (kvCurrentNum >= self.kvBgItems.length) kvCurrentNum = 0;
        width = 0;
        bar.style.width = "0%";
        self.kvShow(kvCurrentNum);
      } else {
        width++;
        bar.style.width = width + "%";
      }
    };
    kvInterval = setInterval(loop, kvDuration / 100);

    // 画面内に入っている時だけKVループ処理実行
    // css animationは動いてしまうため停止
    /*
    const kvElm = this.container.getElementsByClassName('js-seasonal-kv')[0];
    const kvObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (!kvInterval) kvInterval = setInterval(loop, kvDuration / 100);
        } else {
          clearInterval(kvInterval);
          kvInterval = null;
        }
      });
    });
    kvObserver.observe(kvElm);
    */
  }

  /**
   * KV背景画像変更
   * @return {undefined}
   */
  kvShow(index) {
    for (let i = 0, len = this.kvBgItems.length; i < len; i++) {
      if (i == index) {
        this.kvBgItems[i].classList.add('is-show');
      } else {
        this.kvBgItems[i].classList.remove('is-show');
      }
    }
  }

  lazyLoadImages () {
    const images = document.querySelectorAll('.load-img');

    const options = {
      rootMargin: '250px 0px', // ルートと交差するマージン
      threshold: 0.01 // 交差率
    };

    const imageObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const lazyImage = entry.target;
          lazyImage.src = lazyImage.dataset.src; // データ属性から実際の画像のURLを取得し、読み込み
          lazyImage.classList.remove('load-img'); // クラスを削除して、二重読み込みを防ぐ
        }
      });
    }, options);

    images.forEach(image => {
      imageObserver.observe(image);
    });
  }

  fadeInImages () {
    const images = document.querySelectorAll('.lazy');

    const options = {
      rootMargin: '0px 0px', // ルートと交差するマージン
      threshold: 0.01 // 交差率
    };

    const imageObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const lazyImage = entry.target;
          lazyImage.src = lazyImage.dataset.src; // データ属性から実際の画像のURLを取得し、読み込み
          lazyImage.classList.remove('lazy'); // クラスを削除して、二重読み込みを防ぐ
          imageObserver.unobserve(lazyImage); // 画像が読み込まれたら監視を停止
        }
      });
    }, options);

    images.forEach(image => {
      imageObserver.observe(image);
    });
  }

  inView() {
    AOS.init({
      // offset: 150,
      duration: 400,
      easing: 'ease-in-out',
      // delay: 10,
      once: false,
      mirror: false,
      anchorPlacement: 'top-center',
    });
  }

  /**
   * アンカーリンクまでスクロール
   * @param {target} 移動先の要素
   * @return {undefined}
   */
  moveAnchorLink(target) {
    const self = this;
    const pos = this.getAnchorPos(target);
    const speed = 400;

    $.when(
      $("html, body").animate({
        scrollTop: pos
      }, speed, "swing"),
    ).done(() => {
      _.delay(() => {
        const diff = self.getAnchorPos(target);
        if (diff === pos) {
        } else {
          $.when(
            $("html, body").animate({
              scrollTop: diff
            }, 10, "swing"),
          ).done(() => {
            _.delay(() => {
              const diff = self.getAnchorPos(target);
              if (diff === pos) {
              } else {
                $("html, body").animate({
                  scrollTop: diff
                }, 10, "swing");
              }
            }, 1000);
          });
        }
      }, 200);
    });
  }

  /**
   * アンカーリンクの移動先の位置を返却
   * @param {target} 移動先の要素
   * @return {number}
   */
  getAnchorPos(target) {
    let pos = target.offset().top - 50;
    if (this.mql.matches) {
      // sp
      pos = target.offset().top - 30;
    }
    return pos;
  }

  /**
   * PCSPパララックス設定
   */
  setRellax() {
    if (this.rellax && this.rellax.destroy) {
      this.rellax.destroy();
      this.rellax = "";
    }
    if (this.rellax2 && this.rellax2.destroy) {
      this.rellax2.destroy();
      this.rellax2 = "";
    }

    if (this.mql.matches) {
      // sp幅
      if (this.container.getElementsByClassName('js-rellax-sp').length) {
        this.rellax = new Rellax('.js-rellax-sp', {
          speed: 1,
          center: true,
          wrapper: null,
          round: true,
          vertical: true,
          horizontal: false
        });
      }
    } else {
      // pc幅
      if (this.container.getElementsByClassName('js-rellax-pc1').length) {
        this.rellax = new Rellax('.js-rellax-pc1', {
          speed: 1,
          center: true,
          wrapper: null,
          round: true,
          vertical: true,
          horizontal: false
        });
      }
      if (this.container.getElementsByClassName('js-rellax-pc2').length) {
        this.rellax2 = new Rellax('.js-rellax-pc2', {
          speed: 2.5,
          center: true,
          wrapper: null,
          round: true,
          vertical: true,
          horizontal: false
        });
      }
    }
  }

  /**
   * ループ写真
   * 画像読み込み完了時にアニメーション開始
   */
  loopItem() {
    const targetElms = this.container.getElementsByClassName('js-seasonal-list-loop');
    if (targetElms.length) {
      Array.prototype.forEach.call(targetElms, elm => {
        const imgElms = elm.getElementsByTagName('img');
        if (imgElms.length) {
          this.startLoopItem(elm, imgElms);
        }
      });
    }
  }

  startLoopItem(targetElm, imgElms) {
    let loadedCount = 0;

    // 画像が読み込まれたかどうかをチェック
    const checkIfAllImagesLoaded = () => {
      loadedCount++;

      // すべての画像が読み込まれたらクラスを追加
      if (loadedCount === imgElms.length) {
        targetElm.classList.add('is-start');
      }
    };

    Array.prototype.forEach.call(imgElms, elm => {
      if (elm.complete) {
        // 画像が読み込まれていた場合
        checkIfAllImagesLoaded();
      } else {
        // 画像読み込み完了時
        elm.addEventListener('load', checkIfAllImagesLoaded);
        // 画像読み込み失敗時
        elm.addEventListener('error', checkIfAllImagesLoaded);
      }
    });
  }
}
