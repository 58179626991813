/*
 * 化石発掘体験 ページjs
 */

import _ from "underscore";
import $ from "jquery";
import IsPassiveSupport from "../lib/IsPassiveSupport";
import slick from "slick-carousel";
import InView from "../lib/InView";
import UA from "../lib/UA";

export default class Tour {
  constructor() {
    this.container = document.getElementById("tour");
    this.video = document.getElementById("bgVideo");
    this.kvSlider;
    this.breakPoint = 768;
    this.timeout;

    // lib
    this.ua = new UA();

    this.init();
  }

  init() {
    this.bgVideo();
    this.headerFix();
    this.unlockKv();
    this.kvSetHeight();
    this.kvSlider();
    this.inView();
    $('.js-tour-info').on('click', function(e){
        e.preventDefault();
        var pos = $('#tourInfo').offset().top;
        if (window.innerWidth < this.breakPoint) {
            pos += 0;
        } else {
            pos += -100;
        }
        $('html,body').animate({scrollTop:(pos)});
    });
  }

  // 背景動画再生
  bgVideo() {
    $(this.video).on("canplaythrough", e => {
      $(this.video).off("canplaythrough");
      this.video.currentTime = 0;
    });

    window.onload = () => {
      _.delay(() => {
        this.video.play();
        this.video.classList.add("-show");
      }, 1000);
    }
  }

  // スクロールでヘッダー固定解除
  headerFix() {
    const self = this;
    const isPassive = new IsPassiveSupport();

    const header = document.getElementsByClassName("header")[0];
    const tourFixed = document.getElementsByClassName("tourFixed")[0];
    let posTop = window.innerHeight / 3 * 2;

    // スクロールで下部固定
    const onScroll = () => {
      if (this.timeout) {
        cancelAnimationFrame(this.timeout);
      }
      this.timeout = requestAnimationFrame(() => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop > posTop) {
          if (!header.classList.contains("-hide")) {
            header.classList.add("-hide");
            tourFixed.classList.add("-headerHide");
          }
        } else {
          if (header.classList.contains("-hide")) {
            header.classList.remove("-hide");
            tourFixed.classList.remove("-headerHide");
          }
        }
      });
    }
    document.addEventListener(
      "scroll",
      () => {
        onScroll();
      },
      isPassive.check() ? { passive: true } : false
    );
  }

  // KV固定解除処理
  unlockKv() {
    const self = this;
    if (this.ua.device() == "other") {
      // PC
      const mousewheelevent = 'onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll';
      $(document).on(mousewheelevent,function(e){
        const delta = e.originalEvent.deltaY ? -(e.originalEvent.deltaY) : e.originalEvent.wheelDelta ? e.originalEvent.wheelDelta : -(e.originalEvent.detail);

        if (delta < 0){
          // マウスホイールを下にスクロールしたら固定解除
          self.container.classList.remove("-noscroll");
          $(document).off(mousewheelevent);
        }
      });
    } else {
      // PC以外
      let startX, moveX;
      $(document).on("touchstart", () => {
        startX = event.touches[0].pageY;
      });

      $(document).on("touchmove", () => {
        moveX = event.changedTouches[0].pageY;
        if (moveX < startX - 30) {
          // 上にタッチムーブしたら固定解除
          self.container.classList.remove("-noscroll");
          $(document).off("touchstart");
          $(document).off("touchmove");
        }
      });
    }
    _.delay(() => {
      self.container.classList.remove("-noscroll");
      $(document).off(mousewheelevent);
      $(document).off("touchstart");
      $(document).off("touchmove");
    }, 3000);
  }

  // kv高さが足りない時は-relativeクラス追加
  kvSetHeight() {
    const header = this.container.getElementsByClassName("header")[0];
    const kv = this.container.getElementsByClassName("tourKv")[0];
    const inner = kv.getElementsByClassName("tourKv__inner")[0];
    if (window.innerWidth < this.breakPoint) {
      // sp
      if (inner.clientHeight + header.clientHeight + 300 > window.innerHeight) {
        kv.classList.add("-relative");
      }
    } else {
      // pc
      if (inner.clientHeight + header.clientHeight + 80 > window.innerHeight) {
        kv.classList.add("-relative");
      }
    }
  }

  // kv slider
  kvSlider() {
    const target = this.container.getElementsByClassName("js-tourSlide")[0];
    const slideOpt = {
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 0,
      cssEase: "linear",
      speed: 5000,
      dots: false,
      arrows: false,
      draggable: false,
      centerMode: true,
      centerPadding: "12%",
      initialSlide: 3,
      touchMove: false,
      pauseOnFocus: false,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 768, //767px以下のサイズに適用
          settings: {
            slidesToShow: 1,
            initialSlide: 2,
            centerPadding: "20%",
            touchMove: false,
            pauseOnFocus: false,
            pauseOnHover: false,
          }
        }
      ]
    };

    this.kvSlider = $(target).slick(slideOpt);
  }

  // inView event
  inView() {
    const target = this.container.getElementsByClassName("js-inView");
    const callback = elm => {
      if (elm.classList.contains("tourSlide")) {
      }
      if (elm.classList.contains("tourStep__image")) {
        $(elm).on("webkitTransitionEnd mozTransitionEnd oTransitionEnd transitionend", () => {
          elm.classList.add("-view2");
          $(elm).off("webkitTransitionEnd mozTransitionEnd oTransitionEnd transitionend");
        });
      }
    };
    Array.prototype.forEach.call(target, elm => {
      const offset = elm.getAttribute("data-offset") || "";
      new InView(callback, elm, offset);
    });
  }
}
