import $ from "jquery";
import _ from "underscore";
import Barba from "barba.js";
import TopView from "../base/TopView";
import UA from "../lib/UA";

export default class Top {
  constructor() {
    this.topView = new TopView();
    this.viewport = document.getElementsByClassName("topView")[0];
    this.ua = new UA();
  }

  init() {
    this.topView.closeDatabase();
    this.topView.setListElmObj();

    const scrollBooster = this.topView.getScrollBooster();
    const pos = this.topView.getSelectedPos();
    const changePosFlg = this.topView.getChangePosFlg();
    if (changePosFlg) {
      if (pos) {
        if (this.ua.device() == "other") {
          if (scrollBooster) {
            scrollBooster.setPosition({
              x: pos.x,
              y: pos.y
            })
          }
        } else {
        }
      }
    } else {
      if (scrollBooster) {
        // 開始ページがトップ以外の場合
        // 中央表示
        const content = this.viewport.getElementsByClassName("topView__content")[0];
        const posX = 0;
        const posY = content.clientHeight/2 - window.innerHeight/2;
        scrollBooster.setPosition({
          x: posX,
          y: posY
        });
        this.topView.setSelectedPos(posX, posY);
      }
    }
  }

  // leave event
  leave() {
    const topListElm = this.viewport.getElementsByClassName("topList");
    Array.prototype.forEach.call(topListElm, elm => {
      elm.classList.remove("-hover");
    });
  }
}
