/*
 * 個別ページ処理
 * data-namespaceに設定したネームスペース単位でscriptを実行
 * onEnter: ページ表示時
 * onLeave: ページ切替時
 * ページ切替時にイベントが残る場合はイベントをオフにしてメモリリークを避ける
 */

import $ from "jquery";
import _ from "underscore";
import Barba from "barba.js";

import Top from "./Top";
import Menu from "./Menu";
import Database from "./Database";
import DatabaseSearch from "./DatabaseSearch";
import DatabaseDetail from "./DatabaseDetail";
import Topics from "./Topics";
import Museum from "./Museum";
import Access from "./Access";
import Modal from "./Modal";
import Movie from "./Movie";
import Anchor from "./Anchor";
import PageBack from "./PageBack";

import Cookies from "js-cookie";

// lib

// トップページ
const TopIndex = Barba.BaseView.extend({
  namespace: "top",
  onEnter: () => {},
  onEnterCompleted: () => {
    const top = new Top();
    top.init();
  },
  onLeave: () => {},
  onLeaveCompleted: () => {
    const top = new Top();
    top.leave();
  }
});
TopIndex.init();

// データベース検索
const DatabaseIndex = Barba.BaseView.extend({
  namespace: "database",
  onEnter: () => {},
  onEnterCompleted: () => {
    const database = new Database();
    database.init();
  },
  onLeave: () => {},
  onLeaveCompleted: () => {
    const database = new Database();
    database.leave();
  }
});
DatabaseIndex.init();

// データベース検索結果
const DatabaseSearchIndex = Barba.BaseView.extend({
  namespace: "databaseSearch",
  onEnter: () => {},
  onEnterCompleted: () => {
    const databaseSearch = new DatabaseSearch();
    databaseSearch.init();
  },
  onLeave: () => {},
  onLeaveCompleted: () => {
    const databaseSearch = new DatabaseSearch();
    databaseSearch.leave();
  }
});
DatabaseSearchIndex.init();

// データベース詳細
const DatabaseDetailIndex = Barba.BaseView.extend({
  namespace: "databaseDetail",
  onEnter: () => {},
  onEnterCompleted: () => {
    const databaseDetail = new DatabaseDetail();
    databaseDetail.init();
  },
  onLeave: () => {},
  onLeaveCompleted: () => {
    const databaseDetail = new DatabaseDetail();
    databaseDetail.leave();
  }
});
DatabaseDetailIndex.init();

// トピックス一覧
const TopicsIndex = Barba.BaseView.extend({
  namespace: "topics",
  onEnter: () => {},
  onEnterCompleted: () => {
    const topics = new Topics();
    topics.init();
  },
  onLeave: () => {},
  onLeaveCompleted: () => {
    const topics = new Topics();
    topics.leave();
  }
});
TopicsIndex.init();

// 博物館
const MuseumIndex = Barba.BaseView.extend({
  namespace: "museum",
  onEnter: () => {},
  onEnterCompleted: () => {
    const museum = new Museum();
    museum.init();
  },
  onLeave: () => {},
  onLeaveCompleted: () => {
    const museum = new Museum();
    museum.leave();
  }
});
MuseumIndex.init();

// ACCESS
const AccessIndex = Barba.BaseView.extend({
  namespace: "access",
  onEnter: () => {},
  onEnterCompleted: () => {
    const access = new Access();
    access.init();
  },
  onLeave: () => {},
  onLeaveCompleted: () => {
    const access = new Access();
    access.leave();
  }
});
AccessIndex.init();

// ページ移行準備完了時
Barba.Dispatcher.on(
  "newPageReady",
  (currentStatus, oldStatus, container, newPageRawHTML) => {
    const bodyElm = document.getElementsByTagName("body")[0];
    const viewport = document.getElementsByClassName("topView")[0];
    const containerElm = document.getElementsByClassName("container")[0];
    const conceptMovie = document.getElementsByClassName("menu__conceptMovie")[0];
    const topKv = document.getElementById("topKv");
    const topKvInner = topKv.getElementsByClassName("topKv__inner")[0];

    if (currentStatus.namespace == "top") {
      bodyElm.classList.remove("-border");

      const dataArr = Cookies.getJSON("topKeyVisual");
      if (dataArr == undefined) {
        // cookieがない場合
        viewport.classList.add("-hideList");
        viewport.classList.remove("-hide");

        topKv.classList.add("-visible");
        _.delay(() => {
          topKv.classList.add("-show");
          _.delay(() => {
            // kvフェードイン
            topKvInner.classList.add("-show");
            _.delay(() => {
              // kvフェードアウト
              topKv.classList.remove("-show");
              _.delay(() => {
                // kv非表示
                topKv.classList.remove("-visible");
                _.delay(() => {
                  // 一覧表示
                  viewport.classList.remove("-hideList");
                }, 100);
              }, 1200);
            }, 2900);
          }, 400);
        }, 100);

        // loading開始
        const loadingDiv = topKv.getElementsByClassName("topKv__loading")[0];
        const loader = topKv.getElementsByClassName("loader")[0];
        $(loadingDiv).on("webkitTransitionEnd mozTransitionEnd oTransitionEnd transitionend", () => {
          loader.classList.add("-start");
        });

        // クッキー追加
        const expires = 1;
        Cookies.set("topKeyVisual", true, expires);
      } else {
        // 通常表示
        viewport.classList.remove("-hideList");
        const topList = document.getElementsByClassName("topList");
        let delay = 700;
        Array.prototype.forEach.call(topList, elm => {
          if (elm.classList.contains("-active")) {
            delay = 100;
          }
        });
        _.delay(() => {
          viewport.classList.remove("-hide");
        }, delay);
      }
    } else {
      topKv.classList.remove("-visible");
      topKvInner.classList.remove("-show");
      bodyElm.classList.add("-border");
      viewport.classList.add("-hide");
      viewport.classList.add("-hideList");
      conceptMovie.classList.remove("-show");
    }

    const menu  = new Menu();
    const modal = new Modal();
    const movie = new Movie();
    const anchor = new Anchor();
    const pageback = new PageBack();

    if (Barba.HistoryManager.history.length === 1) {
      // 最初の読み込み時
      if (bodyElm.classList.contains("-hide")) {
        _.delay(() => {
          bodyElm.classList.remove("-hide");
        }, 600);
      }
    } else {
      menu.leave();
      modal.leave();
      movie.leave();
      anchor.leave();
      pageback.leave();
    }
    menu.init();
    modal.init();
    movie.init();
    anchor.init();
    pageback.init();
  }
);

// ページ移行完了時
Barba.Dispatcher.on(
  "transitionCompleted",
  (currentStatus, prevStatus) => {
    const links = document.querySelectorAll("a[href]");
    $(links).off("click.linkAll");
    // 同一ページのリンクを無効
    $(links).on("click.linkAll", e => {
      if (
        e.currentTarget.href === window.location.href ||
        e.currentTarget.getAttribute("href") === "#"
      ) {
        e.preventDefault();
        e.stopPropagation();
      }
    });

    const conceptMovie = document.getElementsByClassName("menu__conceptMovie")[0];
    if (currentStatus.namespace == "top") {
      conceptMovie.classList.add("-visible");
      conceptMovie.classList.add("-show");
    } else {
      conceptMovie.classList.remove("-visible");
    }
  }
);

