import $ from "jquery";
import _ from "underscore";
import ModalLib from "../lib/ModalLib";

export default class Modal {
  constructor() {
    this.modal = document.getElementById("modal");
    this.target = document.getElementsByClassName("js-modal");
    this.overlay = this.modal.getElementsByClassName("modal__overlay")[0];

    // lib
    this.modalLib = new ModalLib();
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    const self = this;

    // Open Modal
    this.debouncedOnTargetClick = _.debounce(elm => {
      this.modalLib.openModal(elm);
    }, 0);
    $(this.target).on("click.modalOpen", e => {
      e.preventDefault();
      e.stopPropagation();
      this.debouncedOnTargetClick(e.currentTarget);
    });

    // CloseModal
    this.debouncedOnOverlayClick = _.debounce(() => {
      this.modalLib.closeModal();
    }, 0);
    $(this.overlay).on("click.modalClose", e => {
      e.preventDefault();
      e.stopPropagation();
      this.debouncedOnOverlayClick();
    });
  }

  // ページ離脱時
  leave() {
    // モーダルCLOSE
    this.modal.classList.remove("-show");
    this.modal.classList.remove("-visible");
    const innerElm = this.modal.getElementsByClassName("modal__inner");
    Array.prototype.forEach.call(innerElm, elm => {
      elm.classList.remove("-show");
    });

    // 登録イベント削除
    $(this.target).off("click.modalOpen");
    $(this.overlay).off("click.modalClose");
  }
}
