import $ from "jquery";
import _ from "underscore";
import Barba from "barba.js";

export default class DatabaseSearch {
  constructor() {
    this.resultElm = document.getElementsByClassName("js-dbSearchResult")[0];
    this.errorElm = document.getElementsByClassName("js-dbSearchError")[0];
    this.backBtn = document.getElementsByClassName("js-backSearchForm")[0];
  }

  init() {
    // searchForm
    this.showList();

    // back btn
    this.back();
  }

  // 検索結果を順番に表示
  showList() {
    const list = this.resultElm.getElementsByClassName("dbSearchResult__listItem");
    if (list.length) {
      // 順番に表示
      this.resultElm.classList.add("-show");
      let delay = 0;
      _.delay(() => {
        Array.prototype.forEach.call(list, elm => {
          _.delay(() => {
            elm.classList.add("-show");
          }, delay += 120);
        });
      }, 400);
    } else {
      // エラーdiv表示
      this.errorElm.classList.add("-show");
    }
  }

  // 検索ページへ戻るボタン
  back() {
    // 検索ボタン
    const debouncedOnClickBackBtn = _.debounce(() => {
      Barba.Pjax.goTo("/database/");
    }, 0);
    this.backBtn.addEventListener(
      "click",
      e => {
        e.preventDefault();
        e.stopPropagation();
        debouncedOnClickBackBtn();
      },
      false
    );
  }

  // leave event
  leave() {
  }
}
