/*
 * 共通処理
 * 最初のページ読み込み時に一度だけ実行
 */

import $ from "jquery";
import TopView from "./TopView";
import MenuModal from "./MenuModal";
import PageTop from "./PageTop";

$(() => {
  const topView = new TopView();
  topView.init();

  // メニュー内モーダル
  const menuModal = new MenuModal();
  menuModal.init();

  // pageTop
  const pageTop = new PageTop();
  pageTop.init();

  // PC時にクラス追加
  const ua = navigator.userAgent;
  if (ua.indexOf('iPhone') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) {
    // sp
  } else if (ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
    // tab
  } else {
    // pc
    document.getElementsByTagName("body")[0].classList.add("pc");
  }
});
