/*
 * ヒストリーバック
 */

import _ from "underscore";
import $ from "jquery";

export default class PageBack {
  constructor() {
    this.trigger = document.getElementsByClassName("js-back");
  }

  init() {
    this.back();
  }

  back() {

    // click event
    $(this.trigger).on("click.back", e => {
      e.preventDefault();
      e.stopPropagation();
      history.back();
    });
  }

  leave() {
    $(this.trigger).off("click.back");
  }
}
