/*
 * 美食 ページjs
 */

import InView from "../lib/InView";

export default class Food {
  constructor() {
    this.container = document.getElementById("food");
    this.init();
  }

  init() {
    this.inView();
    this.foodList();
  }

  // inView event
  inView() {
    const target = this.container.getElementsByClassName("js-inView");
    const callback = elm => {};
    Array.prototype.forEach.call(target, elm => {
      const offset = elm.getAttribute("data-offset") || "";
      new InView(callback, elm, offset);
    });
  }

  // SP 食材・飲食店5件づつ表示
  foodList() {
    const target = this.container.getElementsByClassName("js-foodList");
    Array.prototype.forEach.call(target, elm => {
      const btn = elm.getElementsByClassName("js-foodList-more")[0];
      const item = elm.getElementsByTagName("li");
      const itemLength = item.length;
      let count = 4;//5番目の要素までは表示

      btn.addEventListener('click', function (event) {
        event.preventDefault();
        count += 5;
        for (let i=0; i<itemLength; i++) {
          if (i < count) {
            item[i].classList.add('is-show');
          }
        }
        //全部表示したらボタン非表示
        if (count >= itemLength) {
          btn.classList.add('is-hide');
        }
      });

    });
  }

}
