import _ from "underscore";
import $ from "jquery";
import Barba from "barba.js";
import ScrollBooster from "scrollbooster";
import MovieLib from "../lib/MovieLib";
import UA from "../lib/UA";
import IsPassiveSupport from "../lib/IsPassiveSupport";

let scrollBooster = "";
let selectFlg = false;
let changePosFlg = false;   // 位置情報が設定されたらtrue
let selectedPos = {x: 0, y: 0};

// 要素とトップからの位置を保持
// { elm: 要素, top: トップからの位置 }
let listElmObj = [];

export default class TopView {
  constructor() {
    this.viewport = document.getElementsByClassName("topView")[0];
    if (!this.viewport) return false;

    // lib
    this.movieLib = new MovieLib();
    this.ua = new UA();

    this.content = this.viewport.getElementsByClassName("topView__content")[0];
    this.listElm = this.viewport.getElementsByClassName("topList");

    this.ww = window.innerWidth;
    this.imgDelay = 1000;
    this.breakPoint = 769;

    // フラグ
    this.lockFlg = false;
    this.closeFlg = false;
    this.isSp = (window.innerWidth < this.breakPoint) ? true : false;
  }

  // スクロール位置返却
  getSelectedPos() {
    return selectedPos;
  }

  // スクロール位置設定
  setSelectedPos(x, y) {
    selectedPos.x = x;
    selectedPos.y = y;
  }

  getScrollBooster() {
    return scrollBooster;
  }

  // 選択済みフラグ返却
  getChangePosFlg() {
    return changePosFlg;
  }

  init() {
    if (!this.viewport) return false;

    const self = this;

    // ブロック全体サイズ調整
    this.bindEvents();

    if (this.ua.device() == "other") {
      this.setScrollView();
    } else {
      // 中央表示
      const inner = this.viewport.getElementsByClassName("topView__contentInner")[0];
      let centerElm;
      if (this.isSp) {
        centerElm = this.viewport.getElementsByClassName("topView__contentInner")[1]
                                 .getElementsByClassName("topList--center-sp")[0];
      } else {
        centerElm = this.viewport.getElementsByClassName("topView__contentInner")[1]
                                 .getElementsByClassName("topList--center")[0];
      }
      const marginTop = 2;
      const centerElmStyle = window.getComputedStyle(centerElm, null);
      const StyleTop = parseInt(centerElmStyle.getPropertyValue('top'));
      const posTop = inner.clientHeight + StyleTop - window.innerHeight/2 + centerElm.clientHeight/2 + marginTop;
      this.content.scrollTo(0, posTop);
    }

    const imgLoaded = elm => {
      const image = elm.getElementsByClassName("topList__img")[0];
      // inView Event
      self.setInViewObserver(elm);
    }
    Array.prototype.forEach.call(this.listElm, elm => {
      const imageElm = elm.getElementsByClassName("topList__img")[0];
      if (imageElm) {
        const image = imageElm.getElementsByTagName("img")[0]
        image.addEventListener("load", () => {
          imgLoaded(elm);
        });
        image.src = image.getAttribute("data-src");
      } else {
        // inView Event
        self.setInViewObserver(elm);
      }
    });

    this.setListElmObj();


    if (this.ua.device() == "other") {
      this.setLoopEvent();
    } else {
      this.setLoopEventSp();
    }
  }

  setListElmObj() {
    listElmObj = [];
    const inner = this.viewport.getElementsByClassName("topView__contentInner");
    Array.prototype.forEach.call(inner, (elm, index) => {
      const listElm = elm.getElementsByClassName("topList");
      Array.prototype.forEach.call(listElm, element => {
        const style = window.getComputedStyle(element, null);
        const posTop = parseInt(style.getPropertyValue('top')) + (elm.clientHeight * index);
        const obj = {
          elm: element,
          top: posTop,
          height: element.clientHeight
        };
        listElmObj.push(obj);
      });
    });
  }

  setLoopEvent() {
    const self = this;
    const observer = new MutationObserver(records => {
      if (scrollBooster.isDragging) return false;

      const pos = scrollBooster.getUpdate().position;
      const innerHeight = self.content.getElementsByClassName("topView__contentInner")[0].clientHeight;
      const startPos = innerHeight/2 - window.innerHeight/2;
      const endPos = (innerHeight * 5 / 2) - window.innerHeight/2;

      if (pos.y < startPos) {
        const movePos = pos.y + innerHeight;
        self.content.style.transform = 'translate('
          + -pos.x + 'px,'
          + -movePos + 'px' +
        ')'
        scrollBooster.position.y = -movePos
      }

      if (pos.y > endPos) {
        const movePos = pos.y - innerHeight;
        self.content.style.transform = 'translate('
          + -pos.x + 'px,'
          + -movePos + 'px' +
        ')'
        scrollBooster.position.y = -movePos
      }
    });
    const options = {
      attributes: true,
      attributeFilter: ["class", "style"]
    };
    observer.observe(this.content, options);
  }

  setLoopEventSp() {
    const isPassive = new IsPassiveSupport();
    let timeout;

    const innerElm = this.content.getElementsByClassName("topView__contentInner")[0];
    let prevElm;
    let centerElm;
    let nextElm;
    if (this.isSp) {
      prevElm = this.content.getElementsByClassName("topView__contentInner")[0]
                              .getElementsByClassName("topList--center-sp")[0];
      centerElm = this.content.getElementsByClassName("topView__contentInner")[1]
                              .getElementsByClassName("topList--center-sp")[0];
      nextElm = this.content.getElementsByClassName("topView__contentInner")[2]
                            .getElementsByClassName("topList--center-sp")[0];
    } else {
      prevElm = this.content.getElementsByClassName("topView__contentInner")[0]
                            .getElementsByClassName("topList--center")[0];
      centerElm = this.content.getElementsByClassName("topView__contentInner")[1]
                              .getElementsByClassName("topList--center")[0];
      nextElm = this.content.getElementsByClassName("topView__contentInner")[2]
                            .getElementsByClassName("topList--center")[0];
    }

    let prevPos;
    let centerPos;
    let nextPos;

    const reCalc = () => {
      const inner = this.viewport.getElementsByClassName("topView__contentInner")[0];

      const prevElmStyle = window.getComputedStyle(prevElm, null);
      const centerElmStyle = window.getComputedStyle(centerElm, null);
      const nextElmStyle = window.getComputedStyle(nextElm, null);

      const prevStyleTop = parseInt(prevElmStyle.getPropertyValue('top'));
      const centerStyleTop = parseInt(prevElmStyle.getPropertyValue('top')) + inner.clientHeight;
      const nextStyleTop = parseInt(prevElmStyle.getPropertyValue('top')) + (inner.clientHeight * 2);
      prevPos = prevStyleTop;
      centerPos = centerStyleTop;
      nextPos = nextStyleTop;
    }

    reCalc();

    const move = () => {
      const scrollTop = this.content.scrollTop;
      let pos;
      if (scrollTop <= prevPos) {
        pos = scrollTop + innerElm.clientHeight;
        this.content.scrollTo(0, pos);
      } else if (scrollTop >= nextPos) {
        pos = scrollTop - innerElm.clientHeight;
        this.content.scrollTo(0, pos);
      }
    }

    let timeoutId;
    this.content.addEventListener(
      "scroll",
      () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          move();
        }, 500);
      },
      isPassive.check() ? { passive: true } : false
    );
    const debouncedOnResize = _.debounce(() => {
      reCalc();
    }, 1000);
    window.addEventListener(
      "resize",
      () => {
      window.requestAnimationFrame(debouncedOnResize);
    }, false);
  }

  setInViewObserver(elm) {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        observer.disconnect();
        const elements = [].slice.call(elm.parentNode.getElementsByClassName("topList"));
        const index = elements.indexOf(elm);
        const inner = this.viewport.getElementsByClassName("topView__contentInner");
        _.delay(() => {
          // clone要素も同時に表示
          Array.prototype.forEach.call(inner, elm => {
            const target = elm.getElementsByClassName("topList")[index];
            target.classList.add("-show");
          });
        }, this.imgDelay += 120);
        _.delay(() => {
          this.imgDelay = 200;
        }, 1000);
      }
    });
    observer.observe(elm);
  }

  bindEvents() {
    const isPassive = new IsPassiveSupport();
    const conceptMovie = document.getElementsByClassName("menu__conceptMovie")[0];

    // 内部リンククリック
    this.debouncedOnInnerLinkClick = _.debounce(elm => {
      let link = elm.getAttribute("href");
      if (link) {
        if (link !== "#") {
          if (elm.classList.contains("no-barba")) {
            this.viewport.classList.add("-hide");
            conceptMovie.classList.remove("-show");
            _.delay(() => {
              window.location.href = link;
            }, 600);
          } else {
            this.viewport.classList.add("-hide");
            conceptMovie.classList.remove("-show");
            _.delay(() => {
              Barba.Pjax.goTo(link);
            }, 600);
          }
        }
      }
    }, 0);

    // リストクリック
    this.debouncedOnListClick = _.debounce((elm, inner, index) => {
      if (elm.classList.contains("js-topList")) {
        // database詳細ページへ移動
        conceptMovie.classList.remove("-show");
        this.showDatabase(elm, inner, index);
      } else if (elm.classList.contains("js-movieTop")) {
        // モーダルを開いて動画を再生
        this.movieLib.openMovieModal(elm);
      } else {
        let link = elm.getAttribute("href");
        if (link) {
          const target = elm.getAttribute("target");
          if (target && target == "_blank") {
            window.open(link, '_blank');
          } else if (link !== "#") {
            this.viewport.classList.add("-hide");

            if (elm.classList.contains("no-barba")) {
              this.viewport.classList.add("-hide");
              conceptMovie.classList.remove("-show");
              _.delay(() => {
                window.location.href = link;
              }, 600);
            } else {
              if (this.ua.device() == "other") {
                const pos = scrollBooster.getUpdate().position;
                selectedPos.x = pos.x;
                selectedPos.y = pos.y;
              } else {
                selectedPos.x = 0;
                selectedPos.y = this.content.scrollTop;
              }

              changePosFlg = true;

              conceptMovie.classList.remove("-show");
              _.delay(() => {
                Barba.Pjax.goTo(link);
              }, 600);
            }
          }
        }
      }
    }, 0);
    this.debouncedOnListClickSp = _.debounce((elm, inner, index) => {
      if (this.lockFlg) return false;
      if (elm.classList.contains("-hover")) {
        this.debouncedOnListClick(elm, inner, index);
      } else {
        const listElm = document.getElementsByClassName("topList");
        Array.prototype.forEach.call(listElm, elm => {
          elm.classList.remove("-hover");
        });
        elm.classList.add("-hover");
      }
    }, 0);
    const contentInner = this.content.getElementsByClassName("topView__contentInner");
    Array.prototype.forEach.call(contentInner, (inner, index) => {
      const listElm = inner.getElementsByClassName("topList");
      Array.prototype.forEach.call(listElm, elm => {

        // 内部リンクがある場合 クリックイベント
        const innerLink = elm.getElementsByTagName("a");
        if (innerLink.length) {
          Array.prototype.forEach.call(innerLink, elm => {
            elm.addEventListener(
              "click",
              e => {
                e.preventDefault();
                e.stopPropagation();
                this.debouncedOnInnerLinkClick(elm);
              },
              false
            );
          });
        }

        // リストクリックイベント
        let prevPos;
        let movieClickFlg = false;
        if (this.ua.device() == "other") {
          // PC
          elm.addEventListener(
            "mousedown",
            e => {
              prevPos = scrollBooster.getUpdate().position;
              if (e.target.closest(".js-movie")) {
                movieClickFlg = true;
              } else {
                movieClickFlg = false;
              }
            },
            false
          );
          elm.addEventListener(
            "mouseup",
            e => {
              const pos = scrollBooster.getUpdate().position;
              if (
                prevPos &&
                Math.abs(prevPos.y - pos.y) < 8
              ) {
                if (!movieClickFlg) {
                  this.debouncedOnListClick(elm, inner, index);
                }
              }
            },
            false
          );
          elm.addEventListener(
            "click",
            e => {
              e.preventDefault();
              e.stopPropagation();
            },
            false
          );

          // ホバーイベント
          elm.addEventListener(
            "mouseenter",
            e => {
              if (this.lockFlg) return false;
              elm.classList.add("-hover");
            },
            false
          );
          elm.addEventListener(
            "mouseleave",
            e => {
              elm.classList.remove("-hover");
            },
            false
          );
        } else {
          // SP TAB
          elm.addEventListener(
            "click",
            e => {
              e.preventDefault();
              e.stopPropagation();
              this.debouncedOnListClickSp(elm, inner, index);
            },
            false
          );
        }
      });
    });

    // リサイズイベント
    this.debouncedOnResize = _.debounce(() => {
      if (this.ww != window.innerWidth) {
        this.ww = window.innerWidth;
        this.onResize();
      }
    }, 600);
    window.addEventListener(
      "resize",
      e => {
        this.debouncedOnResize();
      },
      isPassive.check() ? { passive: true } : false
    );
  }

  setScrollView() {
    const self = this;

    scrollBooster = new ScrollBooster({
      viewport: self.viewport,
      content: self.content,
      mode: "y",
      emulateScroll: true,
      onUpdate: data => {
        if (selectFlg) return false;
        self.content.style.transform = 'translate('
          + -data.position.x + 'px,'
          + -data.position.y + 'px' +
        ')'
      }
    });

    // 中央表示
    let centerElm;
    let marginTop;
    const inner = this.viewport.getElementsByClassName("topView__contentInner")[0];
    if (!this.isSp) {
      centerElm = this.viewport.getElementsByClassName("topView__contentInner")[1]
                               .getElementsByClassName("topList--center")[0];
      marginTop = 6;
    } else {
      centerElm = this.viewport.getElementsByClassName("topView__contentInner")[1]
                               .getElementsByClassName("topList--center-sp")[0];
      marginTop = 2;
    }
    const centerElmStyle = window.getComputedStyle(centerElm, null);
    const StyleTop = parseInt(centerElmStyle.getPropertyValue('top'));
    scrollBooster.setPosition({
      x: 0,
      y: inner.clientHeight + StyleTop - window.innerHeight/2 + centerElm.clientHeight/2 + marginTop
    });
  }

  // リスト選択時
  showDatabase(elm, inner, index) {
    if (elm.classList.contains("-active")) {
      return false;
    }
    selectFlg = true;

    if (this.lockFlg) return false;
    this.lockFlg = true;

    const link = elm.getAttribute("href");
    elm.classList.add("-active");
    elm.classList.add("-zoom");

    const ratioX = window.innerWidth / elm.clientWidth;
    const ratioY = window.innerHeight / elm.clientHeight;
    const minRatio = Math.round(Math.min(ratioX, ratioY));

    if (this.ua.device() == "other") {
      const pos = scrollBooster.getUpdate().position;
      selectedPos.x = pos.x;
      selectedPos.y = pos.y;
    } else {
      selectedPos.x = 0;
      selectedPos.y = this.content.scrollTop;
    }

    const rect = elm.getBoundingClientRect();
    const posCenterX = window.innerWidth/2 - rect.x - rect.width/2;
    const posCenterY = window.innerHeight/2 - rect.y - rect.height/2;
    elm.setAttribute("style", 'transform: translate3d(' + posCenterX + 'px,' + posCenterY + 'px,10px) scale(' + minRatio + ')');

    changePosFlg = true;

    this.viewport.classList.add("-hide");
    _.delay(() => {
      Barba.Pjax.goTo(link);
      this.lockFlg = false;
    }, 800);
  }

  // 記事を閉じてトップページ表示
  closeDatabase() {
    if (selectFlg == false) return false;
    selectFlg = false;

    if (this.lockFlg) return false;
    this.lockFlg = true;

    this.closeFlg = true;
    let targetElm = "";
    Array.prototype.forEach.call(this.listElm, elm => {
      if (elm.classList.contains("-active")) {
        targetElm = elm;
      }
    });

    if (targetElm) {
      _.delay(() => {
        targetElm.classList.add("-close");
        targetElm.classList.remove("-zoom");
        targetElm.classList.remove("-hover");
        targetElm.removeAttribute("style");
        _.delay(() => {
          targetElm.classList.remove("-active");
          targetElm.classList.remove("-close");
          this.lockFlg = false;
        }, 1200);
      }, 200);
    }

    Barba.Pjax.goTo("/");
  }

  onResize() {
    const isSp = (window.innerWidth < this.breakPoint) ? true : false;

    this.setListElmObj();

    if (this.ua.device() == "other") {
      if (scrollBooster) {
        scrollBooster.updateMetrics();
      }
    }

    this.isSp = isSp;
  }
}
