import $ from "jquery";
import _ from "underscore";

export default class ModalLib {
  constructor() {
    this.body = document.getElementsByTagName("body")[0];
    this.modal = document.getElementById("modal");
  }

  openModal(elm) {
    const d = $.Deferred();

    const id = elm.dataset.modal;
    const targetElm = document.getElementById(id);
    $(this.modal).off("webkitTransitionEnd mozTransitionEnd oTransitionEnd transitionend");
    if (targetElm) {
      this.body.classList.add("-modalOpen");
      targetElm.classList.add("-show");
      this.modal.classList.add("-visible");
      $(this.modal).on("webkitTransitionEnd mozTransitionEnd oTransitionEnd transitionend", () => {
        $(this.modal).off("webkitTransitionEnd mozTransitionEnd oTransitionEnd transitionend");
        d.resolve();
      });
      _.delay(() => {
        this.modal.classList.add("-show");
      }, 100);
    } else {
      // モーダルがなければreject
      d.reject();
    }

    return d.promise();
  }

  closeModal() {
    const d = $.Deferred();

    this.modal.classList.remove("-show");
    $(this.modal).on("webkitTransitionEnd mozTransitionEnd oTransitionEnd transitionend", () => {
      this.body.classList.remove("-modalOpen");
      this.modal.classList.remove("-visible");
      const innerElm = this.modal.getElementsByClassName("modal__inner");
      Array.prototype.forEach.call(innerElm, elm => {
        elm.classList.remove("-show");
      });
      $(this.modal).off("webkitTransitionEnd mozTransitionEnd oTransitionEnd transitionend");
      d.resolve();
    });

    return d.promise();
  }
}
