/*
 * メニュー開閉処理
 */

import _ from "underscore";
import $ from "jquery";
import MenuLib from "../lib/MenuLib";

export default class Menu {
  constructor() {
    this.menuBtn = document.getElementsByClassName("js-menuBtn");
    this.menuModal = document.getElementsByClassName("js-menuModal")[0];
    this.movieList = this.menuModal.getElementsByClassName("menuModal__movieList");

    // lib
    this.menuLib = new MenuLib();

    this.breakPoint = 769;

    // フラグ
    this.lockFlg = false;
  }

  init() {
    this.menuToggle();
  }

  menuToggle() {
    this.debouncedOnMenuBtnClick = _.debounce(elm => {
      if (elm.classList.contains("-open")) {
        this.menuLib.menuClose();
        this.menuClose();
      } else {
        this.menuLib.menuOpen();
        if (window.innerWidth < this.breakPoint) {
          const inner = document.getElementsByClassName("menuModal__movieInner")[0];
          _.delay(() => {
            inner.scrollLeft = (inner.scrollWidth - window.innerWidth) / 2;
          }, 1);
        }
      }
      _.delay(() => {
        this.lockFlg = false;
      }, 600);
    }, 0);
    Array.prototype.forEach.call(this.menuBtn, elm => {
      $(elm).on("click.menuBtn", e => {
        e.preventDefault();
        e.stopPropagation();
        if (this.lockFlg) return false;
        this.lockFlg = true;
        this.debouncedOnMenuBtnClick(elm);
      });
    });
  }

  menuClose() {
    Array.prototype.forEach.call(this.movieList, elm => {
      elm.classList.remove("-hover");
    });
  }

  leave() {
    this.menuClose();
    this.menuLib.menuClose();

    // 登録イベント削除
    Array.prototype.forEach.call(this.menuBtn, elm => {
      $(elm).off("click.menuBtn");
    });
  }
}
