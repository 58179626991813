import $ from "jquery";
import _ from "underscore";
import Barba from "barba.js";
import Cookies from "js-cookie";

export default class Database {
  constructor() {
    this.form = document.getElementsByClassName("js-dbSearchForm")[0];
    this.cookieName = "formDatabase";
    this.obj = {
      "keyword": "",
      "classification1": {
        "id": "",
        "value": "",
      },
      "classification2": {
        "id": "",
        "value": "",
      },
      "period1": {
        "id": "",
        "value": "",
      },
      "period2": {
        "id": "",
        "value": "",
      }
    };
  }

  init() {
    if (!this.form) return false;

    // selectBox
    this.selectBox();

    // clear
    this.clear();

    // searchForm
    this.submit();

    // searchForm
    this.showList();
  }

  selectBox() {
    const selectWrap = this.form.getElementsByClassName("js-searchSelect");

    const changeEvent = _.debounce((elm, sub) => {
      const mainValue = elm.value;
      if (mainValue == "all") {
        this.clearSelectBox(elm, sub);
      } else {
        Array.prototype.forEach.call(sub, subElm => {
          const kind = subElm.getAttribute("data-kind");
          subElm.selectedIndex = 0;
          if (mainValue == kind) {
            subElm.classList.remove("-hide");
            subElm.removeAttribute("disabled");
          } else {
            subElm.classList.add("-hide");
            subElm.setAttribute("disabled", "disabled");
          }
        });
      }
    }, 0);

    Array.prototype.forEach.call(selectWrap, elm => {
      const main = elm.getElementsByClassName("js-searchSelectMain")[0]
                      .getElementsByTagName("select")[0];
      const sub = elm.getElementsByClassName("js-searchSelectSub")[0]
                     .getElementsByTagName("select");
      main.addEventListener(
        "change",
        e => {
          changeEvent(e.target, sub);
        },
        false
      );
    });
  }

  // フォーム内容保存
  saveObj() {
    // キーワード
    const keyword = this.form.getElementsByClassName("js-searchKeyword")[0].value;

    // 分類
    const classification = this.form.getElementsByClassName("js-searchSelectClassification")[0];
    const classification1 = classification.getElementsByClassName("js-searchSelectMain")[0]
                                          .getElementsByTagName("select")[0];
    const classification1_index = classification1.selectedIndex;
    const classification1_id = classification1.value;
    const classification1_value = classification1.options[classification1_index].text
    const classification2 = (() => {
      let classification2 = "";
      let classification2Arr = classification.getElementsByClassName("js-searchSelectSub")[0]
                                             .getElementsByTagName("select");
      Array.prototype.forEach.call(classification2Arr, elm => {
        if (elm.disabled == false) {
          classification2 = elm;
        }
      });
      return classification2;
    })();
    let classification2_id = "all";
    let classification2_value = "すべて";
    if (classification2 != "") {
      const classification2_index = classification2.selectedIndex;
      classification2_id = classification2.value;
      classification2_value = classification2.options[classification2_index].text;
    }

    // 時代
    const period = this.form.getElementsByClassName("js-searchSelectPeriod")[0];
    const period1 = period.getElementsByClassName("js-searchSelectMain")[0]
                                          .getElementsByTagName("select")[0];
    const period1_index = period1.selectedIndex;
    const period1_id = period1.value;
    const period1_value = period1.options[period1_index].text
    const period2 = (() => {
      let period2 = "";
      let period2Arr = period.getElementsByClassName("js-searchSelectSub")[0]
                                             .getElementsByTagName("select");
      Array.prototype.forEach.call(period2Arr, elm => {
        if (elm.disabled == false) {
          period2 = elm;
        }
      });
      return period2;
    })();
    let period2_id = "all";
    let period2_value = "すべて";
    if (period2 != "") {
      const period2_index = period2.selectedIndex;
      period2_id = period2.value;
      period2_value = period2.options[period2_index].text;
    }

    this.obj = {
      "keyword": keyword,
      "classification1": {
        "id": classification1_id,
        "value": classification1_value,
      },
      "classification2": {
        "id": classification2_id,
        "value": classification2_value,
      },
      "period1": {
        "id": period1_id,
        "value": period1_value,
      },
      "period2": {
        "id": period2_id,
        "value": period2_value,
      }
    };

    // Cookieに保存
    const expires = 1;
    const jsonObj = JSON.stringify(this.obj);
    Cookies.set(this.cookieName, jsonObj, expires);
  }

  // 検索結果ページのリンク作成
  createLink() {
    let link = "/database/search/keyword=" + this.obj.keyword;
    link += "&classification1=" + this.obj.classification1.id;
    link += "&classification2=" + this.obj.classification2.id;
    link += "&period1=" + this.obj.period1.id;
    link += "&period2=" + this.obj.period2.id;
    return link;
  }

  // キーワード初期化
  clearKeyword() {
    const keyword = this.form.getElementsByClassName("js-searchKeyword")[0];
    keyword.value = "";
  }

  // selectbox 初期化
  clearSelectBox(main, sub) {
    main.selectedIndex = 0;

    Array.prototype.forEach.call(sub, (subElm, index) => {
      subElm.setAttribute("disabled", "disabled");
      subElm.selectedIndex = 0;
      if (index == 0) {
        subElm.classList.remove("-hide");
      } else {
        subElm.classList.add("-hide");
      }
    });
  }

  clearSelectBoxAll() {
    const selectWrap = this.form.getElementsByClassName("js-searchSelect");

    Array.prototype.forEach.call(selectWrap, elm => {
      const main = elm.getElementsByClassName("js-searchSelectMain")[0]
                      .getElementsByTagName("select")[0];
      const sub = elm.getElementsByClassName("js-searchSelectSub")[0]
                      .getElementsByTagName("select");
      this.clearSelectBox(main, sub);
    });
  }

  clear() {
    const clearBtn = this.form.getElementsByClassName("js-dbSearchFormClear")[0];

    // 検索条件をクリア
    const debouncedOnClickClearBtn = _.debounce(() => {
      this.clearKeyword();
      this.clearSelectBoxAll();
      this.saveObj();
    }, 0);
    clearBtn.addEventListener(
      "click",
      e => {
        e.preventDefault();
        e.stopPropagation();
        debouncedOnClickClearBtn();
      },
      false
    );
  }

  submit() {
    const inputElm = this.form.getElementsByTagName("input");
    const submitBtn = this.form.getElementsByClassName("js-dbSearchFormSubmit")[0];

    // 検索ボタン
    const debouncedOnClickSubmitBtn = _.debounce(() => {
      this.saveObj();
      const link = this.createLink();
      Barba.Pjax.goTo(link);
    }, 0);
    submitBtn.addEventListener(
      "click",
      e => {
        e.preventDefault();
        e.stopPropagation();
        debouncedOnClickSubmitBtn();
      },
      false
    );

    // input上でエンターキーを押したら実行
    Array.prototype.forEach.call(inputElm, elm => {
      elm.addEventListener(
        "keydown",
        e => {
          if (e.keyCode == 13) {
            debouncedOnClickSubmitBtn();
          }
        },
        false
      );
    });
  }

  // 検索結果を順番に表示
  showList() {
    const resultElm = document.getElementsByClassName("js-dbSearchResult")[0];
    const list = resultElm.getElementsByClassName("dbSearchResult__listItem");
    if (list.length) {
      // 順番に表示
      resultElm.classList.add("-show");
      let delay = 0;
      _.delay(() => {
        Array.prototype.forEach.call(list, elm => {
          _.delay(() => {
            elm.classList.add("-show");
          }, delay += 120);
        });
      }, 400);
    }
  }

  // leave event
  leave() {
  }
}
