/*
 * 博物館 ページjs
 */

import _ from "underscore";
import $ from "jquery";
import slick from "slick-carousel";

export default class Museum {
  constructor() {
  }

  init() {
    // intro slider
    this.introSlider();
  }

  introSlider() {
    const target = document.getElementsByClassName("js-museum-introSlider")[0];
    const slickOpt = {
      slidesToShow: 3,
      fade: false,
      autoplay: false,
      dots: false,
      arrows: false,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 768, //767px以下のサイズに適用
          settings: {
            slidesToShow: 1,
            initialSlide: 1,
            centerMode: true,
            centerPadding: "25px"
          }
        }
      ]
    };
    this.introSlider = $(target).slick(slickOpt);
  }

  // leave event
  leave() {
  }
}
