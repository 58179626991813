import $ from "jquery";
import _ from "underscore";
import Barba from "barba.js";

export default class DatabaseDetail {
  constructor() {
  }

  init() {
    // closeBtn
    this.closeBtn();

    // inView
    _.delay(() => {
      this.inView();
    }, 1000);
  }

  closeBtn() {
    const target = document.getElementsByClassName("js-databaseCloseBtn");
    const debouncedOnCloseBtnClick = _.debounce(e => {
      Barba.Pjax.goTo("/");
    }, 0);
    $(target).on("click.closeBtn", e => {
      e.preventDefault();
      e.stopPropagation();
      debouncedOnCloseBtnClick(e);
    });
  }

  // section inview event
  inView() {
    // const target = document.getElementsByClassName("section");

    // Array.prototype.forEach.call(target, elm => {
      // this.setObserver(elm);
    // });
  }

  setObserver(elm) {
    const options = {
      rootMargin: '-20%',
    }
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        observer.disconnect();
        elm.classList.add("-view");
      }
    }, options);
    observer.observe(elm);
  }

  // leave event
  leave() {
  }
}
